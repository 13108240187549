import React, { ReactNode } from "react";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";

const queryClient = new QueryClient({});

const RootElement = ({ children }: { children: ReactNode }) => {
  return (
    <QueryClientProvider client={queryClient}>
      <>
        {children}
        {process.env.GATBY_REACT_QUERY_DEV_TOOLS === "true" && <ReactQueryDevtools />}
      </>
    </QueryClientProvider>
  );
};

export default RootElement;
