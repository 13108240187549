module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-anchor-links/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Steinbrenner Spedition","short_name":"Steinbrenner","description":"JUST THE SAFE WAY! Zuverlässige Lösung für effiziente Logistik.","start_url":"/","background_color":"#fe8903","theme_color":"#fe8903","display":"minimal-ui","cache_busting_mode":"none","theme_color_in_head":false,"icons":[{"src":"/icons-maskable/icon-48x48.png","sizes":"48x48","type":"image/png","purpose":"any"},{"src":"/icons-maskable/icon-72x72.png","sizes":"72x72","type":"image/png","purpose":"any"},{"src":"/icons-maskable/icon-96x96.png","sizes":"96x96","type":"image/png","purpose":"any"},{"src":"/icons-maskable/icon-128x128.png","sizes":"144x144","type":"image/png","purpose":"any"},{"src":"/icons-maskable/icon-192x192.png","sizes":"192x192","type":"image/png","purpose":"any"},{"src":"/icons-maskable/icon-384x384.png","sizes":"384x384","type":"image/png","purpose":"any"},{"src":"/icons-maskable/icon-512x512.png","sizes":"512x512","type":"image/png","purpose":"any"},{"src":"/icons-maskable/icon-512x512.png","sizes":"512x512","type":"image/png","purpose":"maskable"}],"include_favicon":false,"legacy":true,"crossOrigin":"anonymous","cacheDigest":null},
    },{
      plugin: require('../node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[],"workboxConfig":{"globPatterns":["./icons-maskable/"]}},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
